import React from "react"
import useResizeObserver from "use-resize-observer"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"

const Slider = ({
  min = 0,
  max = 8,
  value = 3,
  onChange = () => {},
  vertical,
  sx,
  ...rest
}) => {
  const trackRef = React.useRef()
  const thumbRef = React.useRef()

  const { width: trackWidth = 0, height: trackHeight = 0 } = useResizeObserver({
    ref: trackRef,
  })
  const { width: thumbWidth = 0, height: thumbHeight = 0 } = useResizeObserver({
    ref: thumbRef,
  })

  const diff = React.useRef()

  const end = vertical ? trackHeight - thumbHeight : trackWidth - thumbWidth
  const thumbPosition = ((value - min) / (max - min)) * end

  const handleDown = e => {
    window.addEventListener(
      e.type === "mousedown" ? "mousemove" : "touchmove",
      handleMove
    )
    window.addEventListener(
      e.type === "mousedown" ? "mouseup" : "touchend",
      handleUp
    )

    const { clientX: mouseX, clientY: mouseY } =
      e.type === "touchstart" ? e.changedTouches[0] : e
    const {
      top: trackTop,
      left: trackLeft,
      height: trackHeight,
    } = trackRef.current.getBoundingClientRect()

    const mousePosition = vertical
      ? trackTop + trackHeight - mouseY
      : mouseX - trackLeft

    diff.current = mousePosition - thumbPosition
  }

  const handleMove = e => {
    const { clientX: mouseX, clientY: mouseY } =
      e.type === "touchmove" ? e.changedTouches[0] : e
    const {
      top: trackTop,
      left: trackLeft,
      height: trackHeight,
    } = trackRef.current.getBoundingClientRect()

    const mousePosition = vertical
      ? trackTop + trackHeight - mouseY
      : mouseX - trackLeft

    const newThumbPosition = Math.max(
      0,
      Math.min(mousePosition - diff.current, end)
    )

    const newValue = Math.round(min + (newThumbPosition / end) * (max - min))
    onChange(newValue)
  }

  const handleUp = e => {
    window.removeEventListener(
      e.type === "mouseup" ? "mousemove" : "touchmove",
      handleMove
    )
    window.removeEventListener(
      e.type === "mouseup" ? "mouseup" : "touchend",
      handleUp
    )
  }

  const handleChange = newValue => () => {
    onChange(Math.max(Math.min(newValue, max), min))
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: vertical ? "column-reverse" : "row",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Box as="button" onClick={handleChange(value - 1)}>
        <Icon
          name="minus"
          sx={{
            border: theme => `1px solid ${theme.colors["softened-secondary"]}`,
            borderRadius: "50%",
            fill: "softened-secondary",
            "&:hover": {
              border: theme => `1px solid ${theme.colors["accent"]}`,
              fill: "accent",
            },
          }}
        />
      </Box>
      <Box
        ref={trackRef}
        vertical={vertical}
        sx={{
          m: 3,
          flex: 1,
          width: vertical ? "24px" : "100%",
          height: vertical ? "100%" : "24px",
          position: "relative",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          vertical={vertical}
          sx={{
            width: vertical ? "1px" : "100%",
            height: vertical ? "100%" : "1px",
            background: theme => `linear-gradient(
              to ${vertical ? "top" : "right"},
              ${theme.colors["softened-secondary"]},
              ${theme.colors.secondary}
            )`,
          }}
        />
        <Box
          ref={thumbRef}
          onMouseDown={handleDown}
          onTouchStart={handleDown}
          sx={{
            position: "absolute",
            left: vertical ? "auto" : thumbPosition,
            bottom: vertical ? thumbPosition : "auto",
            width: 24,
            height: 24,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bg: "secondary",
            cursor: "pointer",
            "&:hover": {
              boxShadow: theme => [
                "none",
                `0px 0px 16px 4px ${theme.colors["softened-secondary"]}`,
              ],
            },
          }}
        >
          <Typography
            variant="small"
            sx={{
              pointerEvents: "none",
              userSelect: "none",
              color: "accent",
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
      <Box as="button" onClick={handleChange(value + 1)}>
        <Icon
          name="plus"
          sx={{
            border: theme => `1px solid ${theme.colors["secondary"]}`,
            borderRadius: "50%",
            fill: "secondary",
            "&:hover": {
              border: theme => `1px solid ${theme.colors["accent"]}`,
              fill: "accent",
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default Slider
