import React from "react"
import PopupContext from "./context"

export const PopupContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleOpen = React.useCallback(() => setIsOpen(true), [])
  const handleClose = React.useCallback(() => setIsOpen(false), [])

  return (
    <PopupContext.Provider
      value={{ isOpen, handleOpen, handleClose, setIsOpen }}
    >
      {children}
    </PopupContext.Provider>
  )
}

export const providePopupContext = Component => props =>
  (
    <PopupContextProvider>
      <Component {...props} />
    </PopupContextProvider>
  )

export default PopupContextProvider
