import React from "react"
import { Box } from "~common/components/Primitives"

const Header = ({ children, icon, sx, ...rest }) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      mb: [3, 4],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export default Header
