export const FetchingActionTypes = {
  SET: "SET",
  START_FETCHING: "START_FETCHING",
  FETCH_DONE: "FETCH_DONE",
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case FetchingActionTypes.SET:
      return {
        ...state,
        ...payload,
      }
    case FetchingActionTypes.START_FETCHING:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [payload.jobId]: {
            wsConnection: payload.wsConnection,
            progress: 0,
          },
        },
      }
    case FetchingActionTypes.FETCH_DONE:
      return {
        ...state,
      }
    default:
      throw new Error()
  }
}

export default reducer
