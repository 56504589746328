import React from "react"
import getDate from "date-fns/getDate"
import setDate from "date-fns/setDate"
import getMonth from "date-fns/getMonth"
import setMonth from "date-fns/setMonth"
import getYear from "date-fns/getYear"
import setYear from "date-fns/setYear"
import isExists from "date-fns/isExists"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const InputPill = ({ isInvalid, sx, ...rest }) => (
  <Box
    sx={{
      height: "4ch",
      m: 2,
      p: 2,
      color: "secondary",
      textAlign: "center",
      border: "1px solid",
      borderRadius: 100,
      borderColor: isInvalid ? "error" : "secondary",
      transition: "all 200ms",
      "&:hover, &:focus": {
        bg: "secondary",
        color: "primary",
        fill: "primary",
      },
      ...sx,
    }}
    {...rest}
  />
)

const DatePicker = ({ value, onChange, sx, ...rest }) => {
  const [dateInputValue, setDateInputValue] = React.useState(getDate(value))
  const [monthInputValue, setMonthInputValue] = React.useState(getMonth(value))
  const [yearInputValue, setYearInputValue] = React.useState(getYear(value))

  const [isMonthSelectorDropdownOpen, setIsMonthSelectorDropdownOpen] =
    React.useState(false)

  const isInvalid = !isExists(yearInputValue, monthInputValue, dateInputValue)

  const handleDateChange = e => {
    const newValue = parseInt(e.target.value)
    setDateInputValue(newValue)
    if (isExists(getYear(value), getMonth(value), newValue)) {
      const newDate = setDate(value, newValue)
      onChange(newDate)
    } else {
      onChange(null)
    }
  }

  const handleMonthChange = i => e => {
    e.stopPropagation()
    setMonthInputValue(i)
    if (isExists(getYear(value), i, getDate(value))) {
      const newDate = setMonth(value, i)
      onChange(newDate)
    } else {
      onChange(null)
    }
    setIsMonthSelectorDropdownOpen(false)
  }

  const handleYearChange = e => {
    const newValue = parseInt(e.target.value)
    setYearInputValue(newValue)
    if (isExists(newValue, getMonth(value), getDate(value))) {
      const newDate = setYear(value, newValue)
      onChange(newDate)
    } else {
      onChange(null)
    }
  }

  const openMonthSelector = () => {
    setIsMonthSelectorDropdownOpen(true)
  }

  const monthDropDownRef = React.useRef()

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (
        monthDropDownRef.current &&
        !monthDropDownRef.current.contains(event.target)
      ) {
        setIsMonthSelectorDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [monthDropDownRef])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <InputPill
        as="input"
        type="text"
        maxLength={2}
        value={dateInputValue || ""}
        onChange={handleDateChange}
        isInvalid={isInvalid}
        sx={{
          width: "3em",
        }}
      />
      <InputPill
        as="div"
        onClick={openMonthSelector}
        isInvalid={isInvalid}
        sx={{
          width: "5em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
          "&:hover span, &:focus span": {
            color: "primary",
          },
          "&:hover svg, &:focus svg": {
            fill: "primary",
          },
        }}
      >
        <Typography as="span" variant="normal" sx={{ p: 2 }}>
          {months[monthInputValue].slice(0, 3)}
        </Typography>
        <Icon name="arrow-down" />
        {isMonthSelectorDropdownOpen && (
          <Box
            ref={monthDropDownRef}
            sx={{
              position: ["fixed", "fixed", "absolute"],
              top: [0, 0, "auto"],
              bottom: [0, 0, "auto"],
              left: [0, 0, "auto"],
              right: [0, 0, "auto"],
              width: ["100%", "100%", "5em"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bg: "primary",
              zIndex: 1,
              opacity: 0.9,
              borderRadius: 2,
              boxShadow: theme => [
                "none",
                "none",
                `1px 1px 16px -2px ${theme.colors.secondary}`,
              ],
            }}
          >
            {months.map((month, i) => (
              <Box
                as="button"
                sx={{
                  position: "relative",
                  width: "100%",
                  cursor: "pointer",
                  color: "secondary",
                  border: "none",
                  borderRadius: 0,
                  bg: i === monthInputValue ? "softened-secondary" : "none",
                  "&:hover": {
                    bg: "secondary",
                    color: "primary",
                  },
                }}
                key={month}
                onClick={handleMonthChange(i)}
              >
                <Typography variant="normal">{month.slice(0, 3)}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </InputPill>
      <InputPill
        as="input"
        type="text"
        maxLength={4}
        value={yearInputValue || ""}
        onChange={handleYearChange}
        isInvalid={isInvalid}
        sx={{
          width: "4em",
        }}
      />
    </Box>
  )
}

export default DatePicker
