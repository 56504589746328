import React from "react"
import NavigationContext from "./context"
import useConfiguratorSteps from "~configurator/hooks/useConfiguratorSteps"
import useConfiguratorValidator from "~configurator/hooks/useConfiguratorValidator"

export const NavigationContextProvider = ({ children }) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0)
  const [previousStepIndex, setPreviousStepIndex] = React.useState(0)

  const deltaStepIndex = currentStepIndex - previousStepIndex

  const [isNextDisabled, setIsNextDisabled] = React.useState(false)
  const [isPreviousDisabled, setIsPreviousDisabled] = React.useState(false)

  const steps = useConfiguratorSteps()

  const numberOfSteps = steps.length

  const progress = (currentStepIndex + 1) / numberOfSteps

  const step = steps[currentStepIndex]

  const { error } = useConfiguratorValidator()

  const handleNext = () => {
    if (!error && step.onNext) {
      step.onNext()
    }
    if (currentStepIndex < numberOfSteps - 1) {
      setPreviousStepIndex(currentStepIndex)
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }

  const handlePrevious = React.useCallback(() => {
    !error && step.onPrevious && step.onPrevious()
    if (currentStepIndex > 0) {
      setPreviousStepIndex(currentStepIndex)
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }, [error, step, currentStepIndex, setCurrentStepIndex])

  return (
    <NavigationContext.Provider
      value={{
        currentStepIndex,
        previousStepIndex,
        deltaStepIndex,
        isNextDisabled,
        setIsNextDisabled,
        isPreviousDisabled,
        setIsPreviousDisabled,
        steps,
        step,
        numberOfSteps,
        progress,
        handleNext,
        handlePrevious,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const provideNavigationContext = Component => props =>
  (
    <NavigationContextProvider>
      <Component {...props} />
    </NavigationContextProvider>
  )
