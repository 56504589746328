import React from "react"
import BaseIcon from "~common/components/Icon"

const Icon = ({ sx, size = "5rem", ...rest }) => (
  <BaseIcon
    color="softened-secondary"
    sx={{ width: size, height: size, ...sx }}
    {...rest}
  />
)

export default Icon
