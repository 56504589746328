export const ConfiguratorActionTypes = {
  SET: "SET",
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ConfiguratorActionTypes.SET:
      return {
        ...state,
        ...payload,
      }
    default:
      throw new Error()
  }
}

export default reducer
