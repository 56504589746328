import React from "react"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { pushToGtm } from "~common/utils/gtm"
import { Box } from "~common/components/Primitives"
import ConfiguratorStep from "~configurator/components/ConfiguratorStep"
import Summary from "./components/Summary"
import Mockup from "./components/Mockup"
import Text from "./components/Text"
import SizeSelector from "./components/SizeSelector"
import Price from "./components/Price"
import AddToCart from "./components/AddToCart"

const SummaryStep = () => {
  const { state } = useConfiguratorContext()
  const { ref, from, to, zoom, theme } = state

  React.useEffect(() => {
    pushToGtm({
      event: "configurator_completed",
      ref,
      from,
      to,
      zoom,
      theme,
    })
  }, [ref, from, to, zoom, theme])

  return (
    <ConfiguratorStep
      navigationProps={{ previous: true, next: false, nasaBadge: true }}
    >
      <ConfiguratorStep.Header sx={{ mb: 0 }}>
        <ConfiguratorStep.Title>Summary</ConfiguratorStep.Title>
      </ConfiguratorStep.Header>
      <Box
        sx={{
          mx: [0, "9%", "9%", "16%"],
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
          gridTemplateAreas: [
            '"title" "summary" "mockup" "text" "size" "price" "cart" "nav"',
            '"title" "summary" "mockup" "text" "size" "price" "cart" "nav"',
            '"title title" "mockup summary" "mockup text" "mockup size" "mockup price" "mockup cart" "nav nav"',
          ],
          columnGap: [0, 0, 5],
          gridGap: [2, 3],
        }}
      >
        <Summary
          sx={{
            gridArea: "summary",
            justifySelf: ["start"],
            alignSelf: "flex-start",
          }}
        />
        <Mockup
          sx={{
            gridArea: "mockup",
            justifySelf: ["center", "center", "start"],
            alignSelf: "start",
            maxWidth: 500,
          }}
        />
        <Text
          sx={{
            gridArea: "text",
          }}
        />
        <SizeSelector
          sx={{
            gridArea: "size",
            my: [4, 3],
          }}
        />
        <Price
          sx={{
            gridArea: "price",
          }}
        />
        <AddToCart
          sx={{
            gridArea: "cart",
          }}
        />
      </Box>
    </ConfiguratorStep>
  )
}

export default SummaryStep
