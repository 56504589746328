import React from "react"
import axios from "axios"

const useInlineSvg = url => {
  const [svg, setSvg] = React.useState(null)

  React.useEffect(() => {
    async function fetchSvg() {
      const parser = new DOMParser()
      const { data } = await axios.get(url)
      const { documentElement } = parser.parseFromString(data, "image/svg+xml")
      setSvg(documentElement)
    }
    if (url) {
      fetchSvg()
    }
  }, [url, setSvg])

  return svg
}

export default useInlineSvg
