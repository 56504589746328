import React from "react"
import useMedia from "~common/hooks/useMedia"
import {
  useConfiguratorContext,
  ConfiguratorActionTypes,
} from "~configurator/contexts/configurator"
import { useFetchingContext } from "~configurator/contexts/fetching"
import { useNavigationContext } from "~configurator/contexts/navigation"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Slider from "~common/components/Slider"
import Preview from "~configurator/components/Preview"
import ZoomStepHelp from "~configurator/components/ZoomStepHelp"
import Loading from "~configurator/components/Loading"
import ConfiguratorStep from "~configurator/components/ConfiguratorStep"

const ZoomStep = () => {
  const isAtLeastMd = useMedia("md")

  const { state: configuratorState, dispatch } = useConfiguratorContext()
  const { objs, zoom, zooms } = configuratorState

  const {
    state: fetchingState,
    cancelFetch,
    getPreview,
    getSortedObjs,
  } = useFetchingContext()

  const { previews, jobs } = fetchingState
  const progress = jobs?.previews?.progress

  const { handlePrevious } = useNavigationContext()

  const handleCancelFetch = () => {
    cancelFetch()
    handlePrevious()
  }

  const handleChange = React.useCallback(
    newValue =>
      dispatch({
        type: ConfiguratorActionTypes.SET,
        payload: { zoom: newValue },
      }),
    [dispatch]
  )

  const toTitleCase = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

  const min = [...zooms].sort()[0]
  const max = [...zooms].sort()[zooms.length - 1]

  const isReady =
    previews &&
    zooms.some(z => getPreview({ zoom: z, params: null, layout: "preview" }))

  const preview = getPreview({ params: null, layout: "preview" })

  return isReady ? (
    <ConfiguratorStep>
      <ConfiguratorStep.Header sx={{ position: "relative", zIndex: 10 }}>
        <ConfiguratorStep.Icon
          name="plus-minus"
          size="4rem"
          sx={{
            position: "absolute",
            top: "16%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            opacity: 0.5,
          }}
        />
        <ConfiguratorStep.Title>
          Set the Zoom Level
          <ConfiguratorStep.Help
            title="Select the zoom level"
            gtmParams={{ step: "zoom_step" }}
          >
            <ZoomStepHelp />
          </ConfiguratorStep.Help>
        </ConfiguratorStep.Title>
        <ConfiguratorStep.Description>
          Use the slider to adjust the scale.
        </ConfiguratorStep.Description>
      </ConfiguratorStep.Header>
      <Box
        sx={{
          position: "relative",
          width: ["100%", "100%", "100%", "80%"],
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: ["space-between", "space-between", "center"],
          alignItems: "center",
        }}
      >
        <Preview
          alt={zoom}
          src={preview}
          sx={{
            width: "75vw",
            height: "75vw",
            maxWidth: 420,
            maxHeight: 420,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: [0, 0, "50%"],
            left: [0, "12%", "18%"],
            right: [0, "12%", "auto"],
            transform: ["none", "none", "translateY(-50%)"],
            height: ["75vw", "75vw", 320],
            maxHeight: 420,
            display: "flex",
            flexDirection: "column",
            justifyContent: isAtLeastMd ? "space-around" : "auto",
            userSelect: "none",
            zIndex: 1,
          }}
        >
          {objs.map((obj, i) => (
            <Typography
              variant="tiny"
              key={obj}
              sx={{
                alignSelf: isAtLeastMd
                  ? "center"
                  : i > 4
                  ? "flex-end"
                  : "flex-start",
                flex: !isAtLeastMd && i === 4 ? 1 : 0,
                color:
                  getSortedObjs().indexOf(obj) <= 8 - zoom
                    ? "secondary"
                    : "softened-secondary",
                fontSize: ["auto", "0.8rem"],
              }}
            >
              {toTitleCase(obj)}
            </Typography>
          ))}
        </Box>
        <Slider
          min={min}
          max={max}
          value={zoom}
          onChange={handleChange}
          vertical={isAtLeastMd}
          sx={{
            width: ["100%", "80%", "auto"],
            height: [48, 48, 320],
            my: [0, 4, 0],
            position: ["static", "static", "absolute"],
            top: "50%",
            left: "10%",
            transform: ["none", "none", "translateY(-50%)"],
            ...(isAtLeastMd
              ? {}
              : {
                  "@media screen and (min-height: 600px)": {
                    mt: 24,
                  },
                }),
          }}
        />
      </Box>
    </ConfiguratorStep>
  ) : (
    <Loading
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        bg: "primary",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading.Title>Loading...</Loading.Title>
      <Loading.Spinner progress={progress} />
      <Loading.Text>We are calculating orbits, please wait...</Loading.Text>
      <Loading.CancelButton handleCancel={handleCancelFetch}>
        Cancel
      </Loading.CancelButton>
    </Loading>
  )
}

export default ZoomStep
