import { pushToGtm } from "~common/utils/gtm"
import useMedia from "~common/hooks/useMedia"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { useFetchingContext } from "~configurator/contexts/fetching"
import RefStep from "~configurator/components/RefStep"
import DatesStep from "~configurator/components/DatesStep"
import ZoomStep from "~configurator/components/ZoomStep"
import ThemeStep from "~configurator/components/ThemeStep"
import SummaryStep from "~configurator/components/SummaryStep"

const useConfiguratorSteps = () => {
  const { state: configuratorState } = useConfiguratorContext()
  const { ref, from, to, zoom, zooms, theme, themes, product } =
    configuratorState

  const { fetchPreviews } = useFetchingContext()

  const isAtLeastMd = useMedia("md")

  switch (product?.id) {
    case "custom_poster_30x40cm":
    case "custom_poster_50x70cm":
    case "custom_poster_61x91cm":
    default:
      return [
        {
          Component: RefStep,
          nextButton: !isAtLeastMd,
          onNext: () => {
            pushToGtm({ event: "ref_step_next", ref })
          },
        },
        {
          Component: DatesStep,
          onNext: () => {
            pushToGtm({ event: "dates_step_next", from, to })
            fetchPreviews({
              jobId: "previews",
              ref,
              from,
              to,
              zoom: zooms,
              theme: themes,
              params: null,
              layout: "preview",
            })
          },
        },
        {
          Component: ZoomStep,
          onNext: () => {
            pushToGtm({ event: "zoom_step_next", zoom })
          },
        },
        {
          Component: ThemeStep,
          onNext: () => {
            pushToGtm({ event: "theme_step_next", theme })
            fetchPreviews({
              jobId: "preview",
              ref,
              from,
              to,
              zoom,
              theme,
              layout: ["poster-sm", "poster-md", "poster-lg"],
              // params,
            })
          },
        },
        // {
        //   Component: MessageStep,
        //   onNext: () => {
        //     pushToGtm({ event: "message_step_next", title, text })
        //     fetchPreviews({
        //       jobId: "preview",
        //       ref,
        //       from,
        //       to,
        //       zoom,
        //       theme,
        //       layout,
        //       params,
        //     })
        //   },
        // },
        {
          Component: SummaryStep,
          props: {},
        },
      ]
  }
}

export default useConfiguratorSteps
