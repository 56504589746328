import React from "react"
import { Box, Image } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import themeStepHelpImage from "~images/help/almagesto-theme-step-help.jpg"

const ThemeStepHelp = () => {
  return (
    <Box sx={{ my: [4, 5], px: [4, 5] }}>
      <Typography variant="small" sx={{ textAlign: "justify" }}>
        Select the graphical theme of your Almagesto. Every theme is
        characterized by different color palettes, inspired directly by the
        history of Ptolemy’s book. Even the logotype will be written with a
        different alphabet, based on the context.
      </Typography>
      <Box
        sx={{
          mx: "auto",
          mt: [4, 5],
          width: ["66%", "66%", "50%"],
          aspectRatio: "16/9",
        }}
      >
        <Image src={themeStepHelpImage} alt="theme example" />
      </Box>
    </Box>
  )
}

export default ThemeStepHelp
