import React from "react"
import Configurator from "~configurator/components/Configurator"

const ConfiguratorPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const productId = params.get("productId" || "custom_poster_50x70cm")

  return <Configurator productId={productId} />
}

export default ConfiguratorPage
