import React from "react"
import { Box, Image } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import refStepHelpImage from "~images/help/almagesto-ref-step-help.png"

const RefStepHelp = () => {
  return (
    <Box sx={{ my: [4, 5], px: [4, 5] }}>
      <Typography variant="small" sx={{ textAlign: "justify" }}>
        Select the celestial object that will be considerered as the frame of
        reference. Being the observation point of the Solar System, it won't
        trace any orbit. You will find it fixed in the center of your Almagesto.
      </Typography>
      <Box
        sx={{
          mx: "auto",
          mt: [4, 5],
          width: ["66%", "66%", "50%"],
          aspectRatio: "1/1",
        }}
      >
        <Image src={refStepHelpImage} alt="ref example" />
      </Box>
    </Box>
  )
}

export default RefStepHelp
