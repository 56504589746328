import React from "react"
import CarouselContext from "./context"

export const CarouselContextProvider = ({ children }) => {
  const [value, setValue] = React.useState(0)
  return (
    <CarouselContext.Provider value={{ value, setValue }}>
      {children}
    </CarouselContext.Provider>
  )
}

export const provideCarouselContext = Component => props =>
  (
    <CarouselContextProvider>
      <Component {...props} />
    </CarouselContextProvider>
  )

export default CarouselContextProvider
