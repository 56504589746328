import React from "react"
import { AnimateSharedLayout } from "framer-motion"
import { useSwipeable } from "react-swipeable"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import { provideCarouselContext, useCarouselContext } from "./contexts/carousel"

const Carousel = provideCarouselContext(
  ({ children, initialValue = 0, onChange, sideItems, sx, ...rest }) => {
    const { value, setValue } = useCarouselContext()

    React.useEffect(() => {
      setValue(initialValue)
    }, [setValue, initialValue])

    const handleSelect = i => {
      setValue(i)
      onChange && onChange(i)
    }

    const handleNext = () => {
      const newValue = value === children.length - 1 ? value : value + 1
      handleSelect(newValue)
    }

    const handlePrevious = () => {
      const newValue = value === 0 ? value : value - 1
      handleSelect(newValue)
    }

    const handleSwipe = ({ deltaX }) => {
      if (deltaX > 20) {
        handleNext()
      } else if (deltaX < -20) {
        handlePrevious()
      }
    }

    const handleClick = i => () => {
      handleSelect(i)
    }

    const swipeHandlers = useSwipeable({
      onSwipe: handleSwipe,
    })

    const childrenArray = React.Children.toArray(children)

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...sx,
        }}
        {...rest}
      >
        <Icon
          name="arrow-left"
          onClick={handlePrevious}
          sx={{
            width: "2rem",
            height: "2rem",
            fill: value > 0 ? "secondary" : "softened-secondary",
            cursor: value > 0 ? "pointer" : "auto",
            zIndex: 1,
            "&:hover": {
              fill: value > 0 ? "accent" : "softened-secondary",
            },
          }}
        />
        <Box
          {...swipeHandlers}
          sx={{
            flex: 1,
            display: "grid",
            gridTemplateColumns: `repeat(${2 * sideItems + 1}, 1fr)`,
            placeItems: "center",
            alignSelf: "stretch",
          }}
        >
          <AnimateSharedLayout>
            {childrenArray.map((child, i) => {
              const dx = i - value
              const distance = Math.abs(dx)
              const visible = distance <= sideItems
              const gridColumn = sideItems + dx + 1
              const layoutId = `_${i}`
              const initial = "initial"
              const animate = `d${distance}`

              return (
                visible && (
                  <Box
                    key={i}
                    framer
                    layoutId={layoutId}
                    initial={initial}
                    animate={animate}
                    onClick={handleClick(i)}
                    sx={{
                      gridColumn,
                      cursor: "pointer",
                      placeSelf: "center",
                    }}
                  >
                    {React.cloneElement(child, { layoutId: `_${i}_child` })}
                  </Box>
                )
              )
            })}
          </AnimateSharedLayout>
        </Box>
        <Icon
          name="arrow-right"
          onClick={handleNext}
          sx={{
            width: "2rem",
            height: "2rem",
            fill:
              value < children.length - 1 ? "secondary" : "softened-secondary",
            cursor: value < children.length - 1 ? "pointer" : "auto",
            zIndex: 1,
            "&:hover": {
              fill:
                value < children.length - 1 ? "accent" : "softened-secondary",
            },
          }}
        />
      </Box>
    )
  }
)

export default Carousel
