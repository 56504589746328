import React from "react"
import { useNavigationContext } from "~configurator/contexts/navigation"
import { Box } from "~common/components/Primitives"
import Header from "./components/Header"
import Title from "./components/Title"
import Description from "./components/Description"
import Icon from "./components/Icon"
import Help from "./components/Help"

const ConfiguratorStep = ({ children, sx, ...rest }) => {
  const { previousStepIndex, currentStepIndex } = useNavigationContext()

  const deltaStepIndex = currentStepIndex - previousStepIndex

  return (
    <Box
      framer
      sx={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: [4, 5],
        ...sx,
      }}
      variants={{
        initial: {
          x: `calc(${deltaStepIndex} * 100%)`,
        },
        rest: {
          x: 0,
          transition: {
            type: "spring",
            stiffness: 100,
            damping: 16,
          },
        },
      }}
      initial="initial"
      animate="rest"
      {...rest}
    >
      {children}
    </Box>
  )
}

ConfiguratorStep.Header = Header
ConfiguratorStep.Title = Title
ConfiguratorStep.Description = Description
ConfiguratorStep.Icon = Icon
ConfiguratorStep.Help = Help

export default ConfiguratorStep
