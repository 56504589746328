export const getConfiguratorState = productId => {
  let state = {
    ref: "earth",
    from: new Date("2000-01-01").toISOString().slice(0, 10),
    to: new Date(Date.now()).toISOString().slice(0, 10),
    zoom: 4,
    theme: "almagesto-dark",
    params: { title: "", text: "" },
    objs: [
      "sun",
      "mercury",
      "venus",
      "earth",
      "mars",
      "jupiter",
      "saturn",
      "uranus",
      "neptune",
    ],
    zooms: [4, 3, 5, 2, 6, 1, 7, 0],
    themes: [
      "almagesto-dark",
      "almagesto-light",
      "arabic-alpha",
      "arabic-beta",
      "arabic-gamma",
      "greek-alpha",
      "greek-beta",
      "greek-gamma",
      "latin-alpha",
      "latin-beta",
      "latin-gamma",
    ],
  }

  // switch (productId) {
  //   case "custom_poster_30x40cm":
  //     state = {
  //       ...state,
  //       layout: "poster-sm",
  //     }
  //     break
  //   case "custom_poster_50x70cm":
  //     state = {
  //       ...state,
  //       layout: "poster-md",
  //     }
  //     break
  //   case "custom_poster_61x91cm":
  //     state = {
  //       ...state,
  //       layout: "poster-lg",
  //     }
  //     break
  //   default:
  //     throw Error("Select a product")
  // }

  return state
}
