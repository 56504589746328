import React from "react"
import { createPortal } from "react-dom"
import { alpha } from "@theme-ui/color"
import { AnimatePresence } from "framer-motion"
import { usePopupContext } from "~common/contexts/popup"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"
import Logo from "~common/components/Logo"

const Popup = ({ children, title, closeBtn, onClose }) => {
  const { isOpen, setIsOpen } = usePopupContext()

  const handleClose = () => {
    onClose && onClose()
    setIsOpen(false)
  }

  const [renderable, setRenderable] = React.useState(false)

  React.useEffect(() => {
    setRenderable(true)
  }, [setRenderable])

  return (
    renderable &&
    createPortal(
      <AnimatePresence>
        {isOpen && (
          <Box
            framer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{
              position: "fixed",
              zIndex: 1000,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
              overflowY: "scroll",
              bg: theme => alpha("secondary", 0.7)(theme),
            }}
          >
            <Box
              sx={{
                bg: "primary",
                width: ["90%", "75%", "66%", "50%"],
                maxWidth: 640,
                px: [4, 6, 6],
                py: [4, 5],
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "@media screen and (max-height: 768px)": {
                  py: 4,
                },
                boxShadow: theme => `0 0 64px 8px ${theme.colors.secondary}`,
              }}
            >
              {closeBtn && (
                <Icon
                  name="close"
                  color="secondary"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: [3, 4, 5],
                    right: [3, 4, 5],
                    "&:hover": { cursor: "pointer" },
                  }}
                />
              )}
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  display: "grid",
                  placeItems: "center",
                  my: [4, 5],
                  height: [48, 80],
                }}
              >
                <Logo
                  color="softened-secondary"
                  sx={{
                    width: [48, 80],
                    height: [48, 80],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    opacity: 0.3,
                  }}
                />
                <Typography
                  variant="heading3"
                  as="h1"
                  color="secondary"
                  sx={{ zIndex: 13 }}
                >
                  {title}
                </Typography>
              </Box>
              {children}
            </Box>
          </Box>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}

export default Popup
