import React from "react"
import { greekify } from "~common/utils/format"
import useMedia from "~common/hooks/useMedia"
import useTheme from "~styles/hooks/useTheme"
import {
  useConfiguratorContext,
  ConfiguratorActionTypes,
} from "~configurator/contexts/configurator"
import { useFetchingContext } from "~configurator/contexts/fetching"
import { Box } from "~common/components/Primitives"
import Carousel from "~common/components/Carousel"
import Typography from "~common/components/Typography"
import RadioButton from "~common/components/RadioButton"
import Preview from "~configurator/components/Preview"
import ThemeStepHelp from "~configurator/components/ThemeStepHelp"
import ConfiguratorStep from "~configurator/components/ConfiguratorStep"

const ThemeStep = ({ onNext }) => {
  const isAtLeastMd = useMedia("md")

  const { theme: uiTheme } = useTheme()

  const { state, dispatch } = useConfiguratorContext()
  const { theme, themes } = state
  const index = themes.indexOf(theme)

  const { getPreview } = useFetchingContext()

  const handleChange = i => {
    dispatch({
      type: ConfiguratorActionTypes.SET,
      payload: { theme: themes[i] },
    })
  }

  const handleClick = t => () => {
    dispatch({ type: ConfiguratorActionTypes.SET, payload: { theme: t } })
  }

  const handleSwipe = ({ deltaX }) => {
    if (deltaX > 20) {
      const i = index + 1 < themes.length - 1 ? index + 1 : 0
      dispatch({
        type: ConfiguratorActionTypes.SET,
        payload: { theme: themes[i] },
      })
    } else if (deltaX < -20) {
      const i = index - 1 > 0 ? index - 1 : themes.length - 1
      dispatch({
        type: ConfiguratorActionTypes.SET,
        payload: { theme: themes[i] },
      })
    }
  }

  const preview = getPreview({ params: null, layout: "preview" })

  return (
    <ConfiguratorStep>
      <ConfiguratorStep.Header sx={{ position: "relative", zIndex: 10 }}>
        <ConfiguratorStep.Icon
          name="palette"
          size="4rem"
          sx={{
            position: "absolute",
            left: "50%",
            top: "16%",
            transform: "translate(-50%,-50%)",
            opacity: 0.5,
          }}
        />
        <ConfiguratorStep.Title>
          Choose your Theme
          <ConfiguratorStep.Help
            title="Choose your theme"
            gtmParams={{ step: "theme_step" }}
          >
            <ThemeStepHelp />
          </ConfiguratorStep.Help>
        </ConfiguratorStep.Title>
        <ConfiguratorStep.Description>
          Select your favorite color palette.
        </ConfiguratorStep.Description>
      </ConfiguratorStep.Header>
      <Box
        sx={{
          width: ["100%", "100%", "100%", "80%"],
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: ["center", "center", "space-around"],
          alignItems: "center",
          "@media screen and (min-height: 700px)": {
            mx: 4,
            my: 0,
          },
        }}
      >
        {isAtLeastMd && (
          <Box
            framer
            variants={{
              initial: {
                opacity: 0,
              },
              rest: {
                opacity: 1,
                transition: {
                  when: "beforeChildren",
                  staggerChildren: 0.1,
                },
              },
            }}
            initial="initial"
            animate="rest"
            sx={{
              flex: 1,
              maxWidth: 300,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              p: 4,
            }}
          >
            {themes.map(t => {
              const selected = t === theme
              return (
                <RadioButton
                  key={t}
                  onClick={handleClick(t)}
                  selected={selected}
                  sx={{ width: "100%", my: [2] }}
                >
                  <RadioButton.Bullet />
                  <RadioButton.Text>
                    {greekify(t, { uppercase: true })}
                  </RadioButton.Text>
                </RadioButton>
              )
            })}
          </Box>
        )}
        <Preview
          alt={theme}
          src={preview}
          onSwipe={handleSwipe}
          sx={{
            width: "75vw",
            height: "75vw",
            maxWidth: 420,
            maxHeight: 420,
          }}
        />
        {isAtLeastMd && (
          <Box
            framer
            sx={{
              flex: 1,
              maxWidth: 300,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "50%",
              p: 4,
              mb: 5,
              visibility: "hidden",
            }}
          ></Box>
        )}
        {!isAtLeastMd && (
          <Box
            sx={{
              width: ["80%", "42%"],
              mx: "auto",
              mb: [3, 4],
            }}
          >
            <Carousel
              value={index}
              initialValue={4}
              onChange={handleChange}
              sideItems={2}
              sx={{
                mt: [3, 4],
              }}
            >
              {themes.map((_, i) => (
                <Box
                  framer
                  key={i}
                  variants={{
                    initial: {
                      scale: 1,
                      background: uiTheme.colors.secondary,
                    },
                    d0: {
                      scale: 1.6,
                      background: uiTheme.colors.accent,
                    },
                    d1: {
                      scale: 1,
                      background: uiTheme.colors["softened-secondary"],
                    },
                    d2: {
                      scale: 1,
                      background: uiTheme.colors["softened-secondary"],
                    },
                  }}
                  sx={{
                    width: 3,
                    height: 3,
                    borderRadius: "50%",
                  }}
                />
              ))}
            </Carousel>
            <Typography
              variant="small"
              sx={{
                width: "100%",
                textAlign: "center",
                color: "accent",
                mt: "-8px",
                mb: "-8px",
              }}
            >
              {greekify(theme)}
            </Typography>
          </Box>
        )}
      </Box>
    </ConfiguratorStep>
  )
}

export default ThemeStep
