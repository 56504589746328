import { useConfiguratorContext } from "~configurator/contexts/configurator"

const MAX_TIME_SPAN = 101

const checkIfIsTimeSpanTooLong = (d1, d2) => {
  const dt = Math.abs(d2 - d1) / (1000 * 60 * 60 * 24 * 365)
  return dt > MAX_TIME_SPAN
}

const useConfiguratorValidator = () => {
  const { state } = useConfiguratorContext()
  const { from, to } = state

  const error = checkIfIsTimeSpanTooLong(from, to)

  return { error }
}

export default useConfiguratorValidator
