import React from "react"
import { Box, Image } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import datesStepHelpImage from "~images/help/almagesto-dates-step-help.png"

const DatesStepHelp = () => {
  return (
    <Box sx={{ my: [4, 5], px: [4, 5] }}>
      <Typography variant="small" sx={{ textAlign: "justify" }}>
        Select the starting and ending dates for the orbits' tracing. The
        starting and ending points of each planet's path are marked by a
        triangle and a circle respectively.
      </Typography>
      <Typography
        variant="small"
        sx={{ textAlign: "justify", fontStyle: "italic", my: [3, 4] }}
      >
        Tip: choose a time span neither too long nor too short.
      </Typography>
      <Box
        sx={{
          mx: "auto",
          mt: [4, 5],
          width: ["66%", "66%", "50%"],
          aspectRatio: "1/1",
        }}
      >
        <Image src={datesStepHelpImage} alt="dates example" />
      </Box>
    </Box>
  )
}

export default DatesStepHelp
