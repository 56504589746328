import React from "react"
import Typography from "~common/components/Typography"

const Title = ({ children, sx, ...rest }) => (
  <Typography
    variant="capitalized2"
    sx={{
      my: [3, 4],
      color: "accent",
      fontSize: [2, 4, 5, 6],
      textAlign: "center",
      position: "relative",
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

export default Title
