import React from "react"
import { AnimateSharedLayout, useSpring, useTransform } from "framer-motion"
import { useNavigationContext } from "~configurator/contexts/navigation"
import useConfiguratorValidator from "~configurator/hooks/useConfiguratorValidator"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Button from "~common/components/Button"

const Navigation = ({ sx, ...rest }) => {
  const {
    handleNext,
    handlePrevious,
    currentStepIndex,
    isNextDisabled,
    isPreviousDisabled,
    numberOfSteps,
    progress,
    step,
  } = useNavigationContext()

  const { error } = useConfiguratorValidator()

  const next =
    "nextButton" in step
      ? step.nextButton
      : currentStepIndex < numberOfSteps - 1
  const previous =
    "previousButton" in step ? step.previousButton : currentStepIndex > 0

  const progressSpring = useSpring(0)

  React.useEffect(() => {
    progressSpring.set(progress)
  }, [progress, progressSpring])

  const progressBarWidth = useTransform(
    progressSpring,
    value => `${value * 100}%`
  )

  return (
    <AnimateSharedLayout>
      <Box
        sx={{
          width: "100%",
          pt: [4],
          pb: [4],
          px: [4, 5],
          position: "relative",
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            mx: "auto",
            maxWidth: ["auto", "auto", "75%"],
          }}
        >
          {previous && (
            <Button
              layoutId="previous"
              transition={{
                damping: 10,
                stiffness: 1000,
              }}
              variant={error || isPreviousDisabled ? "disabled" : "secondary"}
              onClick={handlePrevious}
            >
              <Typography variant="normal">Previous</Typography>
            </Button>
          )}
          {next && (
            <Button
              layoutId="next"
              transition={{
                damping: 10,
                stiffness: 1000,
              }}
              variant={error || isNextDisabled ? "disabled" : "secondary"}
              onClick={handleNext}
            >
              <Typography variant="normal">Next</Typography>
            </Button>
          )}
        </Box>
        <Box sx={{ mt: [4] }}>
          <Typography
            variant="capitalized2"
            sx={{ color: "accent", fontWeight: 700 }}
          >
            {`${currentStepIndex + 1}/${numberOfSteps}`}
          </Typography>
          <Box sx={{ width: "100%", bg: "softened-accent" }}>
            <Box
              framer
              sx={{
                bg: "accent",
                height: 2,
              }}
              style={{
                width: progressBarWidth,
              }}
            />
          </Box>
        </Box>
      </Box>
    </AnimateSharedLayout>
  )
}

export default Navigation
