import React from "react"
import {
  useConfiguratorContext,
  ConfiguratorActionTypes,
} from "~configurator/contexts/configurator"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import TextInput from "~common/components/TextInput"

const Text = ({ sx, ...rest }) => {
  const { state, dispatch } = useConfiguratorContext()
  const { params } = state
  const { title } = params

  const handleTextChange = e => {
    dispatch({
      type: ConfiguratorActionTypes.SET,
      payload: { params: { ...state.params, title: e.target.value } },
    })
  }

  return (
    <Box
      sx={{
        ...sx,
      }}
      {...rest}
    >
      <Typography
        variant="small"
        sx={{
          color: "accent",
          my: [3, 4],
          width: "90%",
        }}
      >
        Write something special on your poster (optional)
      </Typography>
      <TextInput
        value={title}
        onChange={handleTextChange}
        name="text"
        maxLength={50}
        placeholder="Your Awesome Text"
      />
    </Box>
  )
}

export default Text
