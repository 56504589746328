import React from "react"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { Box } from "~common/components/Primitives"
import Line from "~common/components/Line"
import RadioButton from "~common/components/RadioButton"

const getSize = productId => {
  switch (productId) {
    case "custom_poster_30x40cm":
      return "30x40cm"
    case "custom_poster_50x70cm":
      return "50x70cm"
    case "custom_poster_61x91cm":
      return "61x91cm"
    default:
      throw Error("Unknown size")
  }
}

const SizeSelector = ({ sx, ...rest }) => {
  const { state, selectProduct } = useConfiguratorContext()
  const { product } = state

  const handleProductChange = productId => () => {
    selectProduct(productId)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        ...sx,
      }}
      {...rest}
    >
      <Line
        align="center"
        color="secondary"
        direction="horizontal"
        sx={{ my: [3, 4] }}
      />
      {[
        "custom_poster_30x40cm",
        "custom_poster_50x70cm",
        "custom_poster_61x91cm",
      ].map((productId, i) => {
        const selected = product.id === productId
        const size = getSize(productId)
        return (
          <RadioButton
            key={i}
            selected={selected}
            onClick={handleProductChange(productId)}
          >
            <RadioButton.Bullet />
            <RadioButton.Text variant="normal" sx={{ textAlign: "left" }}>
              {size}
            </RadioButton.Text>
          </RadioButton>
        )
      })}
      <Line
        align="center"
        color="secondary"
        direction="horizontal"
        sx={{ my: [3, 4] }}
      />
    </Box>
  )
}

export default SizeSelector
