import React from "react"
import { Box } from "~common/components/Primitives"
import Button from "~common/components/Button"
import Typography from "~common/components/Typography"
import Spinner from "~common/components/Spinner"

const Loading = ({ children, sx, ...rest }) => (
  <Box sx={{ ...sx }} {...rest}>
    {children}
  </Box>
)

Loading.Title = ({ children, sx, ...rest }) => (
  <Typography
    variant="heading1"
    sx={{
      color: "accent",
      m: 5,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

Loading.Spinner = function LoadingSpinner({ progress, sx, ...rest }) {
  const interpolatedProgressRef = React.useRef(progress)
  const [interpolatedProgress, setInterpolatedProgress] =
    React.useState(progress)

  React.useEffect(() => {
    const timer = setInterval(() => {
      const delta = progress - interpolatedProgressRef.current
      interpolatedProgressRef.current += 0.1 * delta
      setInterpolatedProgress(interpolatedProgressRef.current)
    }, 100)
    return () => clearInterval(timer)
  }, [progress])

  const percentage = parseInt(interpolatedProgress * 100)

  return <Spinner percentage={percentage} sx={{ ...sx }} {...rest} />
}

Loading.Text = ({ children, sx, ...rest }) => (
  <Typography
    variant="normal"
    sx={{
      textAlign: "center",
      m: 5,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

Loading.CancelButton = ({ children, handleCancel, sx, ...rest }) => (
  <Button variant="secondary" onClick={handleCancel} sx={{ ...sx }} {...rest}>
    {children}
  </Button>
)

export default Loading
