import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import { provideConfiguratorContext } from "~configurator/contexts/configurator"
import { provideFetchingContext } from "~configurator/contexts/fetching"
import {
  provideNavigationContext,
  useNavigationContext,
} from "~configurator/contexts/navigation"
import { Box } from "~common/components/Primitives"
import Navigation from "~configurator/components/Navigation"

export default provideConfiguratorContext(
  provideFetchingContext(
    provideNavigationContext(({ sx, ...rest }) => {
      const { step } = useNavigationContext()
      const { Component } = step

      const { headerHeight } = useLayoutContext()

      return (
        <Box
          as="form"
          sx={{
            width: "100%",
            minHeight: "100vh",
            pt: headerHeight,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            ...sx,
          }}
          onSubmit={e => void e.preventDefault()}
          {...rest}
        >
          <Component />
          <Navigation />
        </Box>
      )
    })
  )
)
