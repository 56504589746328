export const WEBSOCKET_API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "wss://io5gaytm5f.execute-api.eu-central-1.amazonaws.com/staging"
    : "wss://od7znpu1p8.execute-api.eu-central-1.amazonaws.com/production"

export const WITH_REMOTE_CACHE = false

export const STEP_MAP = {
  sun: 12,
  mercury: 8,
  venus: 10,
  earth: 12,
  mars: 12,
  jupiter: 15,
  saturn: 16,
  uranus: 18,
  neptune: 20,
}
