import React from "react"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import Typography from "~common/components/Typography"

const PlanetSelectorItem = ({
  name,
  symbolVariants,
  textVariants,
  sx,
  ...rest
}) => {
  return (
    <Box
      framer
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Icon
        framer
        name={name}
        variants={symbolVariants}
        sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
      />
      <Typography
        variant="normal"
        framer
        variants={textVariants}
        sx={{
          position: "absolute",
          top: "112%",
          display: "flex",
          justifyContent: "center",
          userSelect: "none",
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}

export default PlanetSelectorItem
