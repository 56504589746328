import format from "date-fns/format"
import parse from "date-fns/parse"

export const formatDate = date =>
  format(parse(date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")

export const greekify = (s, options) => {
  const [lang, letter] = s.split("-")
  return `${options && options.uppercase ? lang.toUpperCase() : lang} ${letter
    .replace("alpha", "\u03B1")
    .replace("beta", "\u03B2")
    .replace("gamma", "\u03B3")}`
}
