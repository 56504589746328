import React from "react"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import FetchingContext from "./context"
import reducer, { FetchingActionTypes } from "./reducer"
import {
  STEP_MAP,
  WITH_REMOTE_CACHE,
  WEBSOCKET_API_ENDPOINT,
} from "./constants"

export const FetchingContextProvider = ({ children }) => {
  const { state: configuratorState } = useConfiguratorContext()
  const { ref, from, to, zoom, theme, params, product } = configuratorState
  const { layout } = product

  const [state, dispatch] = React.useReducer(reducer, {
    jobs: {},
    previews: {},
    sorted_objs: {},
  })

  const { previews, sorted_objs } = state

  const hashQuery = (query = {}) => {
    const queryObj = {
      ref,
      from,
      to,
      zoom,
      theme,
      // params,
      layout,
      ...query,
    }
    return JSON.stringify(
      Object.assign(
        {},
        ...Object.keys(queryObj)
          .sort()
          .filter(k => queryObj[k])
          .map(k => ({ [k]: queryObj[k] }))
      )
    )
  }

  const fetchPreviews = ({ jobId, ...rest }) => {
    const queryParams = {
      ref,
      from,
      to,
      zoom,
      theme,
      params,
      layout,
      step: STEP_MAP[ref],
      cache: WITH_REMOTE_CACHE,
      ...rest,
    }

    const job = state.jobs[jobId]
    if (job && job.wsConnection) {
      job.wsConnection.close()
    }

    const wsConnection = new WebSocket(WEBSOCKET_API_ENDPOINT)

    dispatch({
      type: FetchingActionTypes.START_FETCHING,
      payload: {
        jobId,
        wsConnection,
      },
    })

    wsConnection.onopen = () => {
      wsConnection.send(
        JSON.stringify({
          action: "generate",
          payload: {
            ...queryParams,
          },
        })
      )
    }

    wsConnection.onmessage = ({ data }) => {
      const { previews, sorted_objs, progress } = JSON.parse(data)

      if (previews && sorted_objs) {
        dispatch({
          type: FetchingActionTypes.SET,
          payload: {
            previews: Object.assign(
              state.previews || {},
              ...Object.keys(previews).map(k => {
                const [zoom, theme, layout] = k.split("_")
                return {
                  [hashQuery({
                    zoom: Number(zoom),
                    theme,
                    // params,
                    layout,
                  })]: previews[k],
                }
              })
            ),
            sorted_objs: Object.assign(state.sorted_objs || {}, {
              [hashQuery({
                zoom: null,
                theme: null,
                params: null,
                layout: null,
              })]: sorted_objs,
            }),
          },
        })
      }

      dispatch({
        type: FetchingActionTypes.SET,
        payload: {
          jobs: { ...state.jobs, [jobId]: { progress } },
        },
      })

      if (progress === 1) {
        wsConnection.close()
      }
    }

    wsConnection.onclose = () => {
      dispatch({ type: FetchingActionTypes.FETCH_DONE })
    }
  }

  const cancelFetch = () => {
    dispatch({ type: FetchingActionTypes.FETCH_DONE })
  }

  const getPreview = (query = {}) => {
    return previews[hashQuery(query)]
  }

  const getSortedObjs = () => {
    return sorted_objs[
      hashQuery({ zoom: null, theme: null, params: null, layout: null })
    ]
  }

  return (
    <FetchingContext.Provider
      value={{
        state,
        dispatch,
        fetchPreviews,
        cancelFetch,
        getPreview,
        getSortedObjs,
      }}
    >
      {children}
    </FetchingContext.Provider>
  )
}

export const provideFetchingContext = Component => props =>
  (
    <FetchingContextProvider>
      <Component {...props} />
    </FetchingContextProvider>
  )
