import React from "react"
import format from "date-fns/format"
import parse from "date-fns/parse"
import {
  useConfiguratorContext,
  ConfiguratorActionTypes,
} from "~configurator/contexts/configurator"
import { useNavigationContext } from "~configurator/contexts/navigation"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import DatePicker from "~common/components/DatePicker"
import Typography from "~common/components/Typography"
import DatesStepHelp from "~configurator/components/DatesStepHelp"
import ConfiguratorStep from "~configurator/components/ConfiguratorStep"

const MAX_TIME_SPAN = 101

const checkIfIsTimeSpanTooLong = (d1, d2) => {
  const dt = Math.abs(d2 - d1) / (1000 * 60 * 60 * 24 * 365)
  return dt > MAX_TIME_SPAN
}

const DatesStep = () => {
  const { setIsNextDisabled } = useNavigationContext()

  const [isFromDateValid, setIsFromDateValid] = React.useState(true)
  const [isToDateValid, setIsToDateValid] = React.useState(true)
  const [isTimeSpanTooLong, setIsTimeSpanTooLong] = React.useState(false)

  const { state, dispatch } = useConfiguratorContext()
  const { from, to } = state

  React.useEffect(() => {
    setIsNextDisabled(!isFromDateValid || !isToDateValid || isTimeSpanTooLong)
  }, [setIsNextDisabled, isFromDateValid, isToDateValid, isTimeSpanTooLong])

  const handleFromChange = value => {
    if (value) {
      dispatch({
        type: ConfiguratorActionTypes.SET,
        payload: { from: format(value, "yyyy-MM-dd") },
      })
    }
    setIsFromDateValid(!!value)
    setIsTimeSpanTooLong(
      checkIfIsTimeSpanTooLong(value, parse(to, "yyyy-MM-dd", new Date()))
    )
  }

  const handleToChange = value => {
    if (value) {
      dispatch({
        type: ConfiguratorActionTypes.SET,
        payload: { to: format(value, "yyyy-MM-dd") },
      })
    }
    setIsToDateValid(!!value)
    setIsTimeSpanTooLong(
      checkIfIsTimeSpanTooLong(parse(from, "yyyy-MM-dd", new Date()), value)
    )
  }

  return (
    <ConfiguratorStep>
      <ConfiguratorStep.Header>
        <ConfiguratorStep.Icon
          name="calendar"
          sx={{
            width: ["5rem", "7rem", "8rem"],
            height: ["5rem", "7rem", "8rem"],
          }}
        />
        <ConfiguratorStep.Title>
          Celebrate your time
          <ConfiguratorStep.Help
            title="Celebrate your time"
            gtmParams={{ step: "dates_step" }}
          >
            <DatesStepHelp />
          </ConfiguratorStep.Help>
        </ConfiguratorStep.Title>
        <ConfiguratorStep.Description>
          Orbits will be traced within your starting and ending dates.
        </ConfiguratorStep.Description>
      </ConfiguratorStep.Header>
      <Box
        sx={{
          width: ["100%", "100%", "80%", "66%"],
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: "space-around",
          alignItems: "center",
          px: [4, 5],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="capitalized2" sx={{ mt: [4], fontWeight: 500 }}>
            From
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon name="triangle" />
            <DatePicker
              value={parse(from, "yyyy-MM-dd", new Date())}
              onChange={handleFromChange}
              sx={{ ml: [3, 4, 4] }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="capitalized2" sx={{ mt: [4], fontWeight: 500 }}>
            To
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon name="circle" />
            <DatePicker
              value={parse(to, "yyyy-MM-dd", new Date())}
              onChange={handleToChange}
              sx={{ ml: [3, 4, 4] }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: [3, 4],
          visibility: isTimeSpanTooLong ? "visible" : "hidden",
        }}
      >
        <Typography
          as="p"
          variant="tiny"
          sx={{ color: "error", textAlign: "center" }}
        >
          Wow, your time span is very long!
          <br />
          Contact us at info@almagesto.xyz to generate this!
        </Typography>
      </Box>
    </ConfiguratorStep>
  )
}
export default DatesStep
