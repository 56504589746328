import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useFetchingContext } from "~configurator/contexts/fetching"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import useInlineSvg from "~common/hooks/useInlineSvg"
import useFrame from "~shop/hooks/useFrame"
import { Box } from "~common/components/Primitives"
import Spinner from "~common/components/Spinner"

const Mockup = ({ sx, ...rest }) => {
  const { state } = useConfiguratorContext()
  const { params, product } = state
  const { title } = params

  const { getPreview } = useFetchingContext()

  const previewUrl = getPreview()

  const previewSvg = useInlineSvg(previewUrl)

  const previewContainerRef = React.useRef()

  React.useEffect(() => {
    const { current: previewContainer } = previewContainerRef
    if (previewSvg && previewContainer) {
      previewSvg.setAttribute("width", "100%")
      previewSvg.setAttribute("height", "100%")
      previewContainer.replaceChildren(previewSvg)
    }
  }, [previewSvg])

  React.useEffect(() => {
    if (previewSvg) {
      const titleElement = previewSvg.getElementById("title")
      titleElement.innerHTML = title
    }
  }, [previewSvg, title])

  const frame = useFrame(product?.id)

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
      {...rest}
    >
      <GatsbyImage image={frame} width="100%" alt="" />
      {previewSvg ? (
        <Box
          ref={previewContainerRef}
          id="preview-container"
          sx={{
            display: "block",
            width: "53.5%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      ) : (
        <Spinner
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  )
}

export default Mockup
