import React from "react"
import Typography from "~common/components/Typography"

const Description = ({ children, sx, ...rest }) => (
  <Typography
    variant="small"
    sx={{
      my: [3, 4],
      color: "secondary",
      textAlign: "center",
      maxWidth: [300, 360, 640],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

export default Description
