import React from "react"
import { pushToGtm } from "~common/utils/gtm"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import { providePopupContext, usePopupContext } from "~common/contexts/popup"
import Popup from "~common/components/Popup"

const Help = providePopupContext(({ children, title, gtmParams }) => {
  const { isOpen, handleOpen, handleClose } = usePopupContext()

  const handleClick = () => {
    handleOpen()
    pushToGtm({ event: "configurator_step_help", ...gtmParams })
  }

  return (
    <Box as="span">
      <Icon
        as="span"
        name="help"
        onClick={handleClick}
        sx={{
          borderRadius: "50%",
          boxShadow: theme => [
            "none",
            "none",
            `0px 0px 8px 1px ${theme.colors["softened-secondary"]}`,
          ],
          display: "inline-block",
          width: "2rem",
          height: "2rem",
          position: "absolute",
          bottom: "0.3em",
          left: "100%",
          transform: ["none", "translateX(0.5em)"],
          cursor: "pointer",
          fill: "softened-secondary",
          "&:hover": {
            fill: "accent",
            boxShadow: theme => [
              "none",
              `0px 0px 32px 8px ${theme.colors["softened-accent"]}`,
            ],
          },
        }}
      />
      <Popup title={title} isOpen={isOpen} onClose={handleClose} closeBtn>
        {children}
      </Popup>
    </Box>
  )
})

export default Help
