import React from "react"
import { Box, Input } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const TextInput = ({
  value,
  onChange,
  name,
  placeholder,
  maxLength,
  sx,
  ...rest
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Input
        type="text"
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        sx={{ borderRadius: [4, 5], px: [2, 3] }}
      />
      <Typography
        variant="small"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          transform: "translateY(-100%)",
          color: "accent",
          fontWeight: 700,
        }}
      >{`-${50 - value.length}`}</Typography>
    </Box>
  )
}

export default TextInput
