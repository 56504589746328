import React from "react"
import { greekify, formatDate } from "~common/utils/format"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import Typography from "~common/components/Typography"

const PrimaryIcon = ({ invisible, sx, ...rest }) => (
  <Icon
    color="accent"
    sx={{
      visibility: invisible ? "hidden" : "visible",
      ...sx,
    }}
    {...rest}
  />
)

const SecondaryIcon = ({ invisible, sx, ...rest }) => (
  <Icon
    color="softened-accent"
    sx={{
      visibility: invisible ? "hidden" : "visible",
      ...sx,
    }}
    {...rest}
  />
)

const FieldLabel = ({ children, sx, ...rest }) => (
  <Typography
    variant="normal"
    sx={{
      fontWeight: 500,
      borderBottom: theme => `1px dashed ${theme.colors.secondary}`,
      textTransform: "uppercase",
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

const FieldValue = ({ children, sx, ...rest }) => (
  <Typography variant="normal" sx={{ wordSpacing: 4, ...sx }} {...rest}>
    {children}
  </Typography>
)

const Summary = ({ sx, ...rest }) => {
  const { state } = useConfiguratorContext()
  const { ref, from, to, zoom, theme } = state

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto 1fr auto 50%",
        rowGap: [4],
        columnGap: [4, 5],
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <PrimaryIcon name="planet" />
      <FieldLabel>planet</FieldLabel>
      <SecondaryIcon name={ref} />
      <FieldValue sx={{ textTransform: "capitalize" }}>{ref}</FieldValue>
      <PrimaryIcon name="calendar" />
      <FieldLabel>start</FieldLabel>
      <SecondaryIcon name="triangle" />
      <FieldValue>{formatDate(from)}</FieldValue>
      <PrimaryIcon name="calendar" invisible />
      <FieldLabel>end</FieldLabel>
      <SecondaryIcon name="circle" />
      <FieldValue>{formatDate(to)}</FieldValue>
      <PrimaryIcon name="plus-minus" />
      <FieldLabel>zoom</FieldLabel>
      <SecondaryIcon name="plus-minus" invisible />
      <FieldValue>{zoom}</FieldValue>
      <PrimaryIcon name="palette" />
      <FieldLabel>theme</FieldLabel>
      <SecondaryIcon name="palette" invisible />
      <FieldValue>{greekify(theme)}</FieldValue>
    </Box>
  )
}

export default Summary
