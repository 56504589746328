import React from "react"
import useProducts from "~shop/hooks/useProducts"
import ConfiguratorContext from "./context"
import reducer, { ConfiguratorActionTypes } from "./reducer"
import { getConfiguratorState } from "./utils"

export const ConfiguratorContextProvider = ({ children, productId }) => {
  const { getProductById } = useProducts()
  const product = getProductById(productId || "custom_poster_50x70cm")

  const [state, dispatch] = React.useReducer(reducer, {
    product,
    ...getConfiguratorState(productId),
  })

  const selectProduct = productId => {
    const product = getProductById(productId)
    dispatch({
      type: ConfiguratorActionTypes.SET,
      payload: { product },
    })
  }

  return (
    <ConfiguratorContext.Provider
      value={{
        state,
        dispatch,
        product,
        selectProduct,
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  )
}

export const provideConfiguratorContext =
  Component =>
  ({ productId, ...rest }) => {
    return (
      <ConfiguratorContextProvider productId={productId}>
        <Component {...rest} />
      </ConfiguratorContextProvider>
    )
  }
