import React from "react"
import { Box, Image } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import zoomStepHelpGif from "~images/help/almagesto-zoom-step-help.gif"

const ZoomStepHelp = () => (
  <Box sx={{ my: [4, 5], px: [4, 5] }}>
    <Typography variant="small" sx={{ textAlign: "justify" }}>
      Select the zoom level of your Almagesto. Every increase in the level of
      zoom will exclude the outermost planet orbit to leave more space to the
      others. Note that with low zoom levels, the inner Solar System will appear
      very dense.
    </Typography>
    <Box
      sx={{
        mx: "auto",
        mt: [4, 5],
        width: ["66%", "66%", "50%"],
        aspectRatio: "1/1",
      }}
    >
      <Image src={zoomStepHelpGif} alt="zoom selection step help" />
    </Box>
  </Box>
)

export default ZoomStepHelp
