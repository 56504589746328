import React from "react"
import useMedia from "~common/hooks/useMedia"
import useTheme from "~styles/hooks/useTheme"
import {
  useConfiguratorContext,
  ConfiguratorActionTypes,
} from "~configurator/contexts/configurator"
import { useNavigationContext } from "~configurator/contexts/navigation"
import { Box } from "~common/components/Primitives"
import ConfiguratorStep from "~configurator/components/ConfiguratorStep"
import Typography from "~common/components/Typography"
import Carousel from "~common/components/Carousel"
import RefStepHelp from "~configurator/components/RefStepHelp"
import PlanetSelectorItem from "~configurator/components/PlanetSelectorItem"

const RefStep = () => {
  const isAtLeastSm = useMedia("sm")

  const { theme } = useTheme()

  const { state, dispatch } = useConfiguratorContext()
  const { objs } = state

  const { handleNext } = useNavigationContext()

  const handleClick = obj => () => {
    dispatch({ type: ConfiguratorActionTypes.SET, payload: { ref: obj } })
    handleNext()
  }

  const handleSelect = obj => () => {
    dispatch({ type: ConfiguratorActionTypes.SET, payload: { ref: obj } })
  }

  const handleChange = i => {
    const obj = objs[i]
    handleSelect(obj)()
  }

  return (
    <ConfiguratorStep>
      <ConfiguratorStep.Header>
        <ConfiguratorStep.Icon
          name="planet"
          sx={{
            width: ["6rem", "7rem", "8rem"],
            height: ["6rem", "7rem", "8rem"],
          }}
        />
        <ConfiguratorStep.Title>
          Choose your planet{!isAtLeastSm && <br />}
          <ConfiguratorStep.Help
            title="Choose your planet"
            gtmParams={{ step: "ref_step" }}
          >
            <RefStepHelp />
          </ConfiguratorStep.Help>
        </ConfiguratorStep.Title>
        <Typography
          as="span"
          variant="capitalized3"
          sx={{ color: "accent", fontSize: [0, 1] }}
        >
          (or the sun)
        </Typography>
        <ConfiguratorStep.Description>
          Choose your{" "}
          <Box as="span" sx={{ fontStyle: "italic" }}>
            point of observation
          </Box>{" "}
          of the Solar System by selecting a celestial object.
        </ConfiguratorStep.Description>
      </ConfiguratorStep.Header>
      <Carousel
        initialValue={3}
        onChange={handleChange}
        sideItems={isAtLeastSm ? 2 : 1}
        sx={{
          display: ["flex", "flex", "none"],
          width: ["100%", "80%"],
          height: [100],
          mb: [5],
        }}
      >
        {objs.map(obj => (
          <PlanetSelectorItem
            key={obj}
            name={obj}
            variants={{
              initial: {
                opacity: 0,
                margin: 0,
              },
              d0: {
                opacity: 1,
                margin: 16,
                transition: {
                  damping: 3,
                },
              },
              d1: {
                opacity: 1,
                margin: 0,
              },
              d2: {
                opacity: 0.7,
                margin: 0,
              },
            }}
            symbolVariants={{
              initial: {
                scale: 0,
                fill: theme.colors.secondary,
              },
              d0: {
                scale: 1.6,
                fill: theme.colors.accent,
              },
              d1: {
                scale: 0.8,
                fill: theme.colors["softened-secondary"],
              },
              d2: {
                scale: 0.6,
                fill: theme.colors["softened-secondary"],
              },
            }}
            textVariants={{
              initial: {
                scale: 0,
              },
              d0: {
                scale: 1,
                color: theme.colors.accent,
                letterSpacing: "0.2em",
                y: 12,
                opacity: 1,
                transition: {
                  type: "spring",
                },
              },
              d1: {
                scale: 1,
                color: theme.colors.secondary,
                letterSpacing: "0em",
                y: 0,
                opacity: 0.5,
                transition: {
                  type: "spring",
                  delay: 0.2,
                },
              },
              d2: {
                scale: 1,
                color: theme.colors.secondary,
                letterSpacing: "0em",
                y: 0,
                opacity: isAtLeastSm ? 0.2 : 0,
                transition: {
                  type: "spring",
                  delay: 0.2,
                },
              },
            }}
            onClick={handleSelect(obj)}
          />
        ))}
      </Carousel>
      <Box
        sx={{
          display: ["none", "none", "flex"],
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {objs.map(name => (
          <PlanetSelectorItem
            key={name}
            name={name}
            variants={{
              initial: {
                opacity: 0,
                margin: 0,
              },
              rest: {
                opacity: 1,
                margin: 16,
              },
              selected: {
                opacity: 1,
                margin: 16,
                cursor: "pointer",
              },
              hover: {
                margin: 16,
                cursor: "pointer",
              },
            }}
            symbolVariants={{
              initial: {
                scale: 0,
              },
              rest: {
                scale: 1,
                color: theme.colors.secondary,
                transition: {
                  type: "spring",
                  stiffness: 100,
                  damping: 8,
                  mass: 1,
                },
              },
              selected: {
                scale: 1.6,
                fill: theme.colors.accent,
                transition: {
                  type: "spring",
                  stiffness: 250,
                  damping: 16,
                },
              },
              hover: {
                scale: 1.6,
                fill: theme.colors.accent,
                transition: {
                  type: "spring",
                  stiffness: 250,
                  damping: 16,
                },
              },
            }}
            textVariants={{
              initial: {
                scale: 0,
              },
              rest: {
                scale: 1,
                color: theme.colors.secondary,
                transition: {
                  type: "spring",
                  delay: 0.4,
                },
              },
              selected: {
                scale: 1.2,
                color: theme.colors.accent,
                fontWeight: 700,
                letterSpacing: "0.05em",
                y: 16,
                transition: {
                  type: "spring",
                  damping: 16,
                },
              },
              hover: {
                color: theme.colors.accent,
                letterSpacing: "0.2em",
                y: 8,
                transition: {
                  type: "spring",
                  damping: 16,
                },
              },
            }}
            initial="initial"
            animate={"rest"}
            whileHover={"hover"}
            onClick={handleClick(name)}
          />
        ))}
      </Box>
    </ConfiguratorStep>
  )
}

export default RefStep
