import React from "react"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const arcsVariants = {
  initial: {
    rotate: 360,
  },
  rest: {
    rotate: 0,
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      ease: "linear",
      duration: 1,
    },
  },
}

const Spinner = ({ percentage, text, sx, ...rest }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "72px",
      height: "72px",
      ...sx,
    }}
    {...rest}
  >
    <Box
      framer
      variants={arcsVariants}
      initial="initial"
      animate="rest"
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: theme => `3px solid ${theme.colors.secondary}`,
        borderRadius: "50%",
        borderColor: theme => `${theme.colors.secondary} transparent
          ${theme.colors.secondary} transparent`,
      }}
    />
    {percentage ? (
      <Typography variant="normal">{percentage}%</Typography>
    ) : (
      <Typography variant="tiny">Loading...</Typography>
    )}
    {text && <Typography variant="tiny">{text}</Typography>}
  </Box>
)

export default Spinner
