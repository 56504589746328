import { createContext, useContext } from "react"

export const ConfiguratorContext = createContext(null)

export const useConfiguratorContext = () => {
  const funnelContext = useContext(ConfiguratorContext)
  return funnelContext
}

export default ConfiguratorContext
