import React from "react"
import { useSwipeable } from "react-swipeable"
import { Image, Box } from "~common/components/Primitives"
import Spinner from "~common/components/Spinner"

const Preview = ({ alt, src, onSwipe = () => {}, sx, ...rest }) => {
  const isLoading = !src

  const swipeHandlers = useSwipeable({
    onSwipe,
  })

  return (
    <Box
      {...swipeHandlers}
      sx={{
        border: theme => [
          `2px solid ${theme.colors.primary}`,
          `3px solid ${theme.colors.primary}`,
        ],
        borderRadius: "50%",
        boxShadow: theme => [
          `0px 0px 8px 0px ${theme.colors.secondary}`,
          `0px 0px 16px 0px ${theme.colors.secondary}`,
        ],
        transition: "all 500ms",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        ...sx,
      }}
      {...rest}
    >
      {!isLoading ? (
        <Image alt={alt} src={src} sx={{ display: "block" }} />
      ) : (
        <Spinner />
      )}
    </Box>
  )
}

export default Preview
