import React from "react"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"
import PriceTag from "~shop/components/PriceTag"

const Price = ({ sx, ...rest }) => {
  const { state } = useConfiguratorContext()
  const { product } = state
  const { price, discount } = product
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Icon name="cart" color="accent" />
      <PriceTag
        price={price}
        discount={discount}
        decimals={2}
        sx={{ ml: [4, 5] }}
      />
    </Box>
  )
}

export default Price
