import useImage from "~common/hooks/useImage"

const useFrame = productId => {
  const frameSm = useImage(`poster-frame-30x40cm.jpg`)
  const frameMd = useImage(`poster-frame-50x70cm.jpg`)
  const frameLg = useImage(`poster-frame-61x91cm.jpg`)

  const frames = {
    custom_poster_30x40cm: frameSm,
    custom_poster_50x70cm: frameMd,
    custom_poster_61x91cm: frameLg,
  }

  const frame = frames[productId]

  return frame
}

export default useFrame
