import { createContext, useContext } from "react"

export const NavigationContext = createContext()

export const useNavigationContext = () => {
  const ctx = useContext(NavigationContext)
  return ctx
}

export default NavigationContext
