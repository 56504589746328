import React from "react"
import { useConfiguratorContext } from "~configurator/contexts/configurator"
import { useFetchingContext } from "~configurator/contexts/fetching"
import { Box } from "~common/components/Primitives"
import Button from "~common/components/Button"
import PaymentMethods from "~shop/components/PaymentMethods"

const AddToCart = ({ sx, ...rest }) => {
  const { state } = useConfiguratorContext()
  const { ref, from, to, zoom, theme, params, product } = state
  const { id, price, url, name } = product

  const { getPreview } = useFetchingContext()

  const preview = getPreview()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: "space-between",
        alignItems: "center",
        mb: [5, 5, 0],
        ...sx,
      }}
      {...rest}
    >
      <Button
        variant="accent"
        className="snipcart-add-item"
        data-item-id={id}
        data-item-price={price}
        data-item-url={url}
        data-item-name={name}
        data-item-description={`Reference Object: ${ref}, Starting Date: ${from}, Ending Date: ${to}, Zoom Level: ${zoom}, Theme: ${theme}`}
        data-item-image={preview}
        data-item-metadata={JSON.stringify({
          ref,
          from,
          to,
          zoom,
          theme,
          ...params,
        })}
        data-item-stackable="never"
        sx={{ my: [4, 4, 0], order: [2, 2, 2], py: [3, 4] }}
      >
        Add To Cart
      </Button>
      <PaymentMethods
        variant="white-strip"
        sx={{
          my: [4, 4, 0],
          width: ["100%", "100%", "50%"],
          order: [1, 1, 1],
        }}
      />
    </Box>
  )
}

export default AddToCart
