import { createContext, useContext } from "react"

export const FetchingContext = createContext()

export const useFetchingContext = () => {
  const ctx = useContext(FetchingContext)
  return ctx
}

export default FetchingContext
